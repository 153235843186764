import PropTypes from "prop-types";
import React, { useReducer, createContext } from "react";
import globalReducer from "../reducers/globalReducer";

export const GlobalContext = createContext();

export const initialState = {
  aigcLanguage: "",
  alertDialog: {
    action: undefined,
    message: undefined,
    isOpen: false,
    data: undefined,
  },
  allInstitutes: [],
  confirmDialog: {
    action: undefined,
    message: undefined,
    isOpen: false,
    data: undefined,
  },
  currentDraft: undefined,
  currentDraftType: "",
  currentNft: undefined,
  currentProject: undefined,
  errorPageContent: undefined,
  instituteInventors: [],
  instituteManagers: [],
  instituteOwners: [],
  loading: { message: "", isOpen: false },
  navMenuOptions: [],
  nftListings: [],
  searchFilter: {
    query: undefined,
    project_category: [],
    // technology_readiness: [],
    technology_readiness: null,
    project_objectives: [],
    nft_category: [],
    nft_owner: "all",
    nft_status: [],
    amountFrom: "",
    amountTo: "",
    pp_owner: "all",
    creatorAmountFrom: "",
    creatorAmountTo: "",
  },
  searchResults: undefined,
  showToast: { message: "", isOpen: false, type: "" },
  targetPage: "",
  userPosts: [],
  userProfile: undefined,
  patentPoolDraft: undefined,
};

export const GlobalContextProvider = (props) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};

GlobalContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
