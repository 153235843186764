import { theme } from "../../../utils/theme";

export const styles = {
  actionButton: {
    borderRadius: 2,
  },
  dialogActionContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 2,
    justifyContent: "flex-end",
    paddingBottom: 7,
    paddingLeft: 7,
    paddingRight: 9,
    paddingTop: 3,
    position: "relative",
    width: "inherit",
  },
  dialogBodyContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    height: "100%",
    marginBottom: 1,
    paddingBottom: 2,
    paddingLeft: 7,
    paddingRight: 9,
    paddingTop: 7,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    padding: 0,
    paddingTop: `0px !important`,
    width: { xs: "100%", sm: 452 },
  },
  dialogHeader: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: 500,
    letterSpacing: 0.5,
    lineHeight: 1,
    marginBottom: 2,
    textAlign: "left",
  },
  dialogPaper: {
    borderColor: theme.palette.grays[300],
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 2,
  },
  dialogColumn: {
    display: "flex",
    flexDirection: "column",
  },
  dialogText: {
    color: theme.palette.secondary.light,
    fontSize: theme.typography.fontSize * 0.88,
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  icon: {
    height: 20,
    width: 20,
  },
  iconButton: {
    alignItems: "center",
    borderRadius: 9,
    color: theme.palette.accent.main,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: 0.5,
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  image: {
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: 60,
    width: 60,
  },
  imageWrapper: {
    marginRight: 3,
  },
  link: {
    alignItems: "center",
    color: theme.palette.grays[600],
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 1,
    fontWeight: 500,
    justifyContent: "center",
    minWidth: 64,
    paddingLeft: 4,
    paddingRight: 4,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
