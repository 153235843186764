import { initialState } from "../contexts/GlobalContext";

const globalReducer = (state, action) => {
  switch (action.type) {
    case "SET_ALERT_DIALOG":
      return { ...state, alertDialog: action.payload };

    case "STORE_ALL_INSTITUTES":
      return { ...state, allInstitutes: action.payload };

      case "SET_CONFIRM_DIALOG":
      return { ...state, confirmDialog: action.payload };

    case "SET_CURRENT_DRAFT_TYPE":
      const { currentDraftType, currentProject } = action.payload;
      return { ...state, currentDraftType, currentProject };

    case "SET_CURRENT_NFT":
      return { ...state, currentNft: action.payload };

    case "SET_ERROR_PAGE_CONTENT":
      return { ...state, errorPageContent: action.payload };

    case "SET_LOADING":
      return { ...state, loading: action.payload };

    case "SET_NAV_MENU_OPTIONS":
      return { ...state, navMenuOptions: action.payload };

    case "SET_SEARCH_FILTER":
      return { ...state, searchFilter: action.payload };

    case "SET_TARGET_PAGE":
      return { ...state, targetPage: action.payload };

    case "SHOW_TOAST":
      return { ...state, showToast: action.payload };

    case "STORE_CURRENT_DRAFT":
      return { ...state, currentDraft: action.payload };

    case "STORE_CURRENT_PROJECT":
      return { ...state, currentProject: action.payload };

    case "STORE_INSTITUTE_INVENTORS":
      return { ...state, instituteInventors: action.payload };

    case "STORE_INSTITUTE_MANAGERS":
      return { ...state, instituteManagers: action.payload };

    case "STORE_INSTITUTE_OWNERS":
      return { ...state, instituteOwners: action.payload };

    case "STORE_NFT_LISTINGS":
      return { ...state, nftListings: action.payload };

    case "STORE_SEARCH_RESULTS":
      return {
        ...state,
        searchResults: action.payload,
        // showToast: {
        //   action: undefined,
        //   message: `${action?.payload?.length} results found`,
        //   isOpen: true,
        // },
      };

    case "STORE_USER_POSTS":
      return { ...state, userPosts: action.payload };

    case "STORE_USER_PROFILE":
      return { ...state, userProfile: action.payload };

    case "RESET":
      console.log("RESET", initialState);
      return initialState;

    case "STORE_PATENT_POOL_DRAFT":
      return { ...state, patentPoolDraft: action.payload };

    case "STORE_PATENT_POOL_LISTINGS":
      return { ...state, patentPoolListings: action.payload };

    case "SET_CURRENT_PATENT_POOL":
      return { ...state, currentPatentPool: action.payload };

    case "SET_AIGC_LANGUAGE":
      return { ...state, aigcLanguage: action.payload };

    default:
      return state;
  }
};

export default globalReducer;
