import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  Slide,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import {
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiCloseLine,
  RiLogoutBoxRLine,
  RiMenuLine,
  RiNotification3Line,
} from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Logo from "../../Logo";
import NavLanguageSelect from "../NavLanguageSelect";

import { GlobalContext } from "../../../../contexts/GlobalContext";
import { logout } from "../../../../utils/apiUtils";
import { NAV_OPTIONS_PUBLIC } from "../../../../utils/optionUtils";
import { PRIVATE_ROUTES } from "../../../../utils/routesUtil";
import { styles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const NavMenu = () => {
  const { state, dispatch } = useContext(GlobalContext);
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  let navigate = useNavigate();
  const [accordianExpanded, setAccordianExpanded] = useState("userProfile");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const { navMenuOptions, userProfile } = state;

  const handleClickUserProfile = (panel) => (evt, isExpanded) => {
    setAccordianExpanded(isExpanded ? panel : false);
  };

  const handleClickUserProfileItem = (path) => (evt) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  const handleLogout = () => {
    setIsMenuOpen(false);
    logout(dispatch);
  };

  return (
    <Box sx={styles.navMenuContainer}>
      {/* <Box sx={styles.navIcon}>
        <RiNotification3Line size={"1.4em"} />
      </Box> */}

      <Box
        onClick={() => {
          navigate("/chatbot");
        }}
        sx={styles.ip8chatNav}
      />

      <Box sx={styles.navIcon} onClick={() => setIsMenuOpen(true)}>
        <RiMenuLine size="1.4em" />
      </Box>

      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{ sx: styles.dialogPaper }}
        TransitionComponent={Transition}
      >
        <Box sx={styles.dialogHeaderContainer}>
          <Box sx={styles.dialogHeader}>
            <Logo lightTheme />

            <Box sx={styles.grow} />

            <Box sx={styles.dialogHeaderIcon}>
              <RiCloseLine onClick={() => setIsMenuOpen(false)} size="1.4em" />
            </Box>
          </Box>
        </Box>

        <Box sx={styles.dialogListContainer}>
          {userProfile ? (
            <>
              <Accordion
                expanded={accordianExpanded === "userProfile"}
                onChange={handleClickUserProfile("userProfile")}
                sx={styles.dialogAccordion}
              >
                <AccordionSummary sx={styles.dialogAccordionSummary}>
                  <Box sx={styles.dialogTextContainer}>
                    <Box sx={styles.dialogTextIcon}>
                      <BiUserCircle size={"1.4em"} />
                    </Box>
                    <Typography sx={styles.dialogAccordionSummaryText}>
                      {userProfile.full_name}
                    </Typography>

                    <Box sx={styles.dialogTextIcon}>
                      {accordianExpanded === "userProfile" ? (
                        <RiArrowUpSFill />
                      ) : (
                        <RiArrowDownSFill />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>

                <AccordionDetails>
                  <Box sx={styles.dialogAccordionMenuItemsContainer}>
                    {navMenuOptions.map((key) => (
                      <Box
                        key={key}
                        sx={styles.dialogAccordionMenuItem}
                        onClick={handleClickUserProfileItem(
                          PRIVATE_ROUTES.get(key).PATH
                        )}
                      >
                        <Box sx={styles.dialogAccordionMenuItemIcon}>
                          {PRIVATE_ROUTES.get(key).ICON}
                        </Box>

                        {t(PRIVATE_ROUTES.get(key).LABEL)}
                      </Box>
                    ))}

                    <Box
                      sx={styles.dialogAccordionMenuItem}
                      onClick={handleLogout}
                    >
                      <Box sx={styles.dialogAccordionMenuItemIcon}>
                        <RiLogoutBoxRLine size="1.2em" />
                      </Box>
                      {t("Logout")}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ) : (
            Array.from(NAV_OPTIONS_PUBLIC).map(([key, value]) => (
              <Link
                key={value.LABEL}
                to={value.PATH}
                style={{ marginLeft: 12, textDecoration: "none" }}
              >
                <Typography sx={styles.dialogText}>{t(value.LABEL)}</Typography>
              </Link>
            ))
          )}

          <Box sx={styles.grow} />

          <Box sx={styles.dialogFooter}>
            <NavLanguageSelect
              horizontal={{ left: 16 }}
              showValue
              vertical={{ bottom: 40 }}
            />
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default NavMenu;
