import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { LANGUAGE_KEY } from "../utils/apiUtils";

import translationEn from "./en/translation.json";
import translationZhCn from "./zh-cn/translation.json";
import translationZhTw from "./zh-tw/translation.json";

const isChineseDomain = window.location.hostname.endsWith(".ip8value.cn");
const defaultLanguage = isChineseDomain
  ? "zh-CN"
  : localStorage.getItem(LANGUAGE_KEY) || "en";

i18n.use(LanguageDetector).init({
  debug: false,
  lng: defaultLanguage,
  fallbackLng: {
    en: ["en"],
    "zh-CN": ["zh_Hans"],
    "zh-TW": ["zh_Hant"],
    default: "en",
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },

  resources: {
    en: {
      translations: translationEn,
    },
    zh_Hans: {
      translations: translationZhCn,
    },
    zh_Hant: {
      translations: translationZhTw,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

export default i18n;
