import ip_pin from "../assets/icons/ip_pin.png";
import flask from "../assets/icons/flask.png";
import rocket from "../assets/icons/rocket.png";

import { GLOBAL_ROUTES, PUBLIC_ROUTES } from "./routesUtil";

const enableFeature = process.env.REACT_APP_HIDE_NFT_FEATURE === "false";

export const NAV_OPTIONS_PUBLIC = new Map([
  ["LOGIN", PUBLIC_ROUTES.get("LOGIN")],
  ["SIGNUP", PUBLIC_ROUTES.get("SIGNUP")],
]);

export const NAV_OPTIONS_GLOBAL = new Map([
  ["CHATBOT", GLOBAL_ROUTES.get("CHATBOT")],
  ["DEMAND_PORTAL", GLOBAL_ROUTES.get("DEMAND_PORTAL")],
  // ["MARKETPLACE", GLOBAL_ROUTES.get("MARKETPLACE")],
  // ["PATENT_POOL_MARKETPLACE", GLOBAL_ROUTES.get("PATENT_POOL_MARKETPLACE")],
  // ["INSTITUTIONS", GLOBAL_ROUTES.get("INSTITUTIONS")],
]);

// Conditionally add "MARKETPLACE" and "PATENT_POOL_MARKETPLACE" if the NFT feature is enabled
if (enableFeature) {
  NAV_OPTIONS_GLOBAL.set("MARKETPLACE", GLOBAL_ROUTES.get("MARKETPLACE"));
  NAV_OPTIONS_GLOBAL.set(
    "PATENT_POOL_MARKETPLACE",
    GLOBAL_ROUTES.get("PATENT_POOL_MARKETPLACE")
  );
}

export const NAV_MENU_OPTIONS = [
  "POSTS",
  // "LIKED_POSTS",
  "MY_DEMANDS",
  // "My_NFTs",
  // "MY_PATENT_POOL",
  "ACCOUNT",
];

// Conditionally add "My_NFTs" and "MY_PATENT_POOL" if the NFT feature is enabled
if (enableFeature) {
  NAV_MENU_OPTIONS.push("My_NFTs", "MY_PATENT_POOL");
}

export const NAV_MENU_OPTIONS_ADMIN = [
  "POSTS",
  "MY_DEMANDS",
  "DASHBOARD",
  "USERS",
  // "My_NFTs",
  // "MY_PATENT_POOL",
  "ACCOUNT",
];

// Conditionally add "My_NFTs" and "MY_PATENT_POOL" if the NFT feature is enabled
if (enableFeature) {
  NAV_MENU_OPTIONS_ADMIN.push("My_NFTs", "MY_PATENT_POOL");
}

export const NAV_MENU_OPTIONS_PREMIUM = ["POSTS", "MY_DEMANDS", "ACCOUNT"];
// export const NAV_MENU_OPTIONS = ["POSTS", "My_NFTs", "ACCOUNT"];
// export const NAV_MENU_OPTIONS_ADMIN = ["POSTS", "My_NFTs", "USERS", "ACCOUNT"];

export const PROJECT_TYPES = new Map([
  [
    "STARTUP",
    {
      KEY: 0, // value for backend
      LABEL: "Startup",
      VALUE: "spinoff",
      ICON: rocket,
    },
  ],
  [
    "TECH_OFFER",
    {
      KEY: 1, // value for backend
      LABEL: "Technology Offer",
      VALUE: "ip",
      ICON: ip_pin,
    },
  ],
  [
    "RESEARCH_LAB",
    {
      KEY: 2, // value for backend
      LABEL: "Research Lab",
      VALUE: "lab",
      ICON: flask,
    },
  ],
]);

export const LANGUAGES_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      KEY: "en",
    },
  ],
  [
    "CHINESE",
    {
      LABEL: "简体字",
      KEY: "zh-CN",
    },
  ],
  [
    "CHINESE_TRADITIONAL",
    {
      LABEL: "繁體字",
      KEY: "zh-TW",
    },
  ],
]);

export const TRANSLATE_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      VALUE: "en",
    },
  ],
  [
    "ZH-CN",
    {
      LABEL: "简体中文",
      VALUE: "zh-cn",
    },
  ],
  [
    "ZH-TW",
    {
      LABEL: "繁体中文",
      VALUE: "zh-tw",
    },
  ],
  // [
  //   "FRENCH",
  //   {
  //     LABEL: "French",
  //     VALUE: "FR",
  //   },
  // ],
  // [
  //   "GERMAN",
  //   {
  //     LABEL: "German",
  //     VALUE: "DE",
  //   },
  // ],
  // [
  //   "ITALIAN",
  //   {
  //     LABEL: "Italian",
  //     VALUE: "IT",
  //   },
  // ],
  // [
  //   "JAPANESE",
  //   {
  //     LABEL: "Japanese",
  //     VALUE: "JA",
  //   },
  // ],
  // [
  //   "KOREAN",
  //   {
  //     LABEL: "Korean",
  //     VALUE: "KO",
  //   },
  // ],
]);

export const USER_ROLE_OPTIONS = new Map([
  [
    "MANAGER",
    {
      LABEL: "Manager",
      VALUE: "owner",
    },
  ],
  [
    "INVENTOR",
    {
      LABEL: "Inventor",
      VALUE: "inventor",
    },
  ],
]);

export const STARTUP_SIZE = new Map([
  [
    "MICRO",
    {
      LABEL: "Micro (1-10)",
      TAG: "Micro",
      VALUE: "Micro (1-10)",
    },
  ],
  [
    "SMALL",
    {
      LABEL: "Small (11-50)",
      TAG: "Small",
      VALUE: "Small (11-50)",
    },
  ],
  [
    "MEDIUM",
    {
      LABEL: "Medium (51-200)",
      TAG: "Medium",
      VALUE: "Medium (51-200)",
    },
  ],
  [
    "HUGE",
    {
      LABEL: "Huge (Over 200)",
      TAG: "Huge",
      VALUE: "Huge (Over 200)",
    },
  ],
]);

export const RESEARCH_LAB_SIZE = new Map([
  [
    "SMALL",
    {
      LABEL: "Small (1-10)",
      TAG: "Small",
      VALUE: "Small (1-10)",
    },
  ],
  [
    "MEDIUM",
    {
      LABEL: "Medium (11-30)",
      TAG: "Medium",
      VALUE: "Medium (11-30)",
    },
  ],
  [
    "HUGE",
    {
      LABEL: "Huge (Over 30)",
      TAG: "Huge",
      VALUE: "Huge (Over 30)",
    },
  ],
]);

export const COMMERCIAL_READINESS_LEVELS = new Map([
  [
    "CRL_0",
    {
      DESCRIPTION: "Unspecified",
      LABEL: "CRL 0",
      VALUE: 0,
    },
  ],
  [
    "CRL_1",
    {
      DESCRIPTION:
        "Basic hypothesis that a new technology would be commercially viable",
      LABEL: "CRL 1",
      VALUE: 1,
    },
  ],
  [
    "CRL_2",
    {
      DESCRIPTION: "Basic market awareness demonstrated",
      LABEL: "CRL 2",
      VALUE: 2,
    },
  ],
  [
    "CRL_3",
    {
      DESCRIPTION: "Applications of technology found",
      LABEL: "CRL 3",
      VALUE: 3,
    },
  ],
  [
    "CRL_4",
    {
      DESCRIPTION:
        "Identify support needed from supply chain, plus any certification and regulatory requirements",
      LABEL: "CRL 4",
      VALUE: 4,
    },
  ],
  [
    "CRL_5",
    {
      DESCRIPTION: "Financial model for sales, costs and margins established",
      LABEL: "CRL 5",
      VALUE: 5,
    },
  ],
  [
    "CRL_6",
    {
      DESCRIPTION:
        "Confirm exploitation routes; form partnerships across the value chain; and certification and regulatory  requirements underway",
      LABEL: "CRL 6",
      VALUE: 6,
    },
  ],
  [
    "CRL_7",
    {
      DESCRIPTION: "Financial model/commercial viability validated",
      LABEL: "CRL 7",
      VALUE: 7,
    },
  ],
  [
    "CRL_8",
    {
      DESCRIPTION:
        "First commercial system built; route to market established; market assumptions updated",
      LABEL: "CRL 8",
      VALUE: 8,
    },
  ],
  [
    "CRL_9",
    {
      DESCRIPTION: "Technology has been introduced to market",
      LABEL: "CRL 9",
      VALUE: 9,
    },
  ],
]);

export const TECHNOLOGY_READINESS_LEVELS = new Map([
  [
    "TRL_0",
    {
      DESCRIPTION: "Unspecified",
      LABEL: "TRL 0",
      VALUE: 0,
    },
  ],
  [
    "TRL_1",
    {
      DESCRIPTION: "Basic principles observed",
      LABEL: "TRL 1",
      VALUE: 1,
    },
  ],
  [
    "TRL_2",
    {
      DESCRIPTION: "Technology concept formulated",
      LABEL: "TRL 2",
      VALUE: 2,
    },
  ],
  [
    "TRL_3",
    {
      DESCRIPTION: "Experimental proof of concept",
      LABEL: "TRL 3",
      VALUE: 3,
    },
  ],
  [
    "TRL_4",
    {
      DESCRIPTION: "Technology validated in lab",
      LABEL: "TRL 4",
      VALUE: 4,
    },
  ],
  [
    "TRL_5",
    {
      DESCRIPTION: "Technology validated in relevant environment",
      LABEL: "TRL 5",
      VALUE: 5,
    },
  ],
  [
    "TRL_6",
    {
      DESCRIPTION: "Technology demonstrated in relevant environment",
      LABEL: "TRL 6",
      VALUE: 6,
    },
  ],
  [
    "TRL_7",
    {
      DESCRIPTION: "System prototype demonstration in operational environment",
      LABEL: "TRL 7",
      VALUE: 7,
    },
  ],
  [
    "TRL_8",
    {
      DESCRIPTION: "System complete and qualified",
      LABEL: "TRL 8",
      VALUE: 8,
    },
  ],
  [
    "TRL_9",
    {
      DESCRIPTION: "Actual system proven in operational environment",
      LABEL: "TRL 9",
      VALUE: 9,
    },
  ],
]);

export const COMMERCIAL_READINESS_LEVEL_QUESTIONNAIRE = new Map([
  [
    "PRODUCT_DEFINITION_DESIGN",
    {
      LABEL: "Product Definition/Design",
      VALUE: "definition",
      OPTIONS: [
        {
          LABEL: "One or more initial product hypotheses have been defined",
          VALUE: 1,
        },
        {
          LABEL:
            "Mapping product/system attributes against customer needs has highlighted a clear value proposition",
          VALUE: 2,
        },
        {
          LABEL:
            "The product/system has been scaled from laboratory to pilot scale and issues that may affect achieving full scale have been identified",
          VALUE: 3,
        },
        {
          LABEL:
            "Comprehensive customer value proposition model has been developed, including a detailed understanding of product/system design specifications, required certifications, and trade-offs",
          VALUE: 4,
        },
        {
          LABEL:
            "Product/system final design optimization has been completed, required certifications have been obtained, and product/system has incorporated detailed customer and product requirements",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "COMPETITION",
    {
      LABEL: "Competitive Landscape",
      VALUE: "competition",
      OPTIONS: [
        {
          LABEL:
            "Secondary market research has been performed and basic knowledge of potential applications and competitive landscape have been identified",
          VALUE: 1,
        },
        {
          LABEL:
            "Primary market research to prove the product/system commercial feasibility has been completed and basic understanding of competitive products/systems has been demonstrated",
          VALUE: 2,
        },
        {
          LABEL:
            "Comprehensive market research to prove the product/system commercial feasibility has been completed and intermediate understanding of competitive products/systems has been demonstrated",
          VALUE: 3,
        },
        {
          LABEL:
            "Competitive analysis to illustrate unique features and advantages of the product/system compared to competitive products/systems has been completed",
          VALUE: 4,
        },
        {
          LABEL:
            "Full and complete understanding of the competitive landscape, target application(s), competitive products/systems, and market has been achieved",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "TEAM",
    {
      LABEL: "Team",
      VALUE: "team",
      OPTIONS: [
        {
          LABEL:
            "No team or company in place (single individual, no legal entity)",
          VALUE: 1,
        },
        {
          LABEL:
            "Solely technical or non-technical founder(s) running the company with no outside assistance",
          VALUE: 2,
        },
        {
          LABEL:
            "Solely technical or non-technical founder(s) running the company with assistance from outside advisors/mentors and/or incubator/accelerator",
          VALUE: 3,
        },
        {
          LABEL:
            "Balanced team with technical and business development/commercialization experience running the company with assistance from outside advisors/mentors",
          VALUE: 4,
        },
        {
          LABEL:
            "Balanced team with all capabilities onboard (e.g. sales, marketing, customer service, operations, etc.) running the company with assistance from outside advisors/mentors",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "MARKET",
    {
      LABEL: "Go-To-Market",
      VALUE: "market",
      OPTIONS: [
        {
          LABEL:
            "Initial business model and value proposition have been defined",
          VALUE: 1,
        },
        {
          LABEL:
            "Customers/partners have been interviewed to understand their pain points/needs, and business model and value proposition have been refined based on customer/partner feedback",
          VALUE: 2,
        },
        {
          LABEL:
            "Market and customer/partner needs and how those translate to product requirements have been defined, and initial relationships have been developed with key stakeholders across the value chain",
          VALUE: 3,
        },
        {
          LABEL:
            "Partnerships have been formed with key stakeholders across the value chain (e.g. suppliers, partners, service providers, and customers)",
          VALUE: 4,
        },
        {
          LABEL:
            "Supply agreements with suppliers and partners are in place and initial purchase orders from customers have been received",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "SUPPLY",
    {
      LABEL: "Manufacturing/Supply Chain",
      VALUE: "supply",
      OPTIONS: [
        {
          LABEL:
            "Potential suppliers, partners, and customers have been identified and mapped in an initial value chain analysis",
          VALUE: 1,
        },
        {
          LABEL:
            "Relationships have been established with potential suppliers, partners, service providers, and customers and they have provided input on product and manufacturability requirements",
          VALUE: 2,
        },
        {
          LABEL:
            "Manufacturing process qualifications (e.g. QC/QA) have been defined and are in progress",
          VALUE: 3,
        },
        {
          LABEL:
            "Products/systems have been pilot manufactured and sold to initial customers",
          VALUE: 4,
        },
        {
          LABEL:
            "Full scale manufacturing and widespread deployment of product/system to customers and/or users has been achieved",
          VALUE: 5,
        },
      ],
    },
  ],
]);

export const TECHNOLOGY_READINESS_LEVEL_QUESTIONNAIRE = new Map([
  [
    "TECHNOLOGY",
    {
      LABEL: "Technology",
      VALUE: "technology",
      OPTIONS: [
        {
          LABEL:
            "Project work is beyond basic research and technology concept has been defined",
          VALUE: 1,
        },
        {
          LABEL:
            "Applied research has begun and practical application(s) have been identified",
          VALUE: 2,
        },
        {
          LABEL:
            "Preliminary testing of technology components has begun, and technical feasibility has been established in a laboratory environment",
          VALUE: 3,
        },
        {
          LABEL:
            "Initial testing of integrated product/system has been completed in a laboratory environment",
          VALUE: 4,
        },
        {
          LABEL:
            "Laboratory scale integrated product/system demonstrates performance in the intended application(s)",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "PRODUCT_DEVELOPMENT",
    {
      LABEL: "Product Development",
      VALUE: "development",
      OPTIONS: [
        {
          LABEL:
            "Pilot scale product/system has not been tested in the intended application(s)",
          VALUE: 1,
        },
        {
          LABEL:
            "Pilot scale product/system has been tested in the intended application(s)",
          VALUE: 2,
        },
        {
          LABEL:
            "Demonstration of a full scale product/system prototype has been completed in the intended application(s)",
          VALUE: 3,
        },
        {
          LABEL:
            "Actual product/system has been proven to work in its near-final form under a representative set of expected conditions and environments",
          VALUE: 4,
        },
        {
          LABEL:
            "Product/system is in final form and has been operated under the full range of operating conditions and environments",
          VALUE: 5,
        },
      ],
    },
  ],
  [
    "PRODUCT_DEFINITION_DESIGN",
    {
      LABEL: "Product Definition/Design",
      VALUE: "definition",
      OPTIONS: [
        {
          LABEL: "One or more initial product hypotheses have been defined",
          VALUE: 1,
        },
        {
          LABEL:
            "Mapping product/system attributes against customer needs has highlighted a clear value proposition",
          VALUE: 2,
        },
        {
          LABEL:
            "The product/system has been scaled from laboratory to pilot scale and issues that may affect achieving full scale have been identified",
          VALUE: 3,
        },
        {
          LABEL:
            "Comprehensive customer value proposition model has been developed, including a detailed understanding of product/system design specifications, required certifications, and trade-offs",
          VALUE: 4,
        },
        {
          LABEL:
            "Product/system final design optimization has been completed, required certifications have been obtained, and product/system has incorporated detailed customer and product requirements",
          VALUE: 5,
        },
      ],
    },
  ],
]);

export const SEARCH_FILTER_OPTIONS = new Map([
  [
    "CATEGORY",
    {
      LABEL: "Category",
      VALUE: "labels",
    },
  ],
  [
    "READINESS",
    {
      LABEL: "Technology Readiness Level (TRL)",
      VALUE: "readiness",
    },
  ],
  [
    "TYPE",
    {
      LABEL: "Project Type",
      VALUE: "type",
    },
  ],
]);

export const SEARCH_SORT_BY = new Map([
  // [
  //   "ALPHABETICAL",
  //   {
  //     LABEL: "Alphabetical",
  //     VALUE: "Alphabetical",
  //   },
  // ],
  [
    "RELEVANCE",
    {
      LABEL: "Relevance",
      VALUE: "relevance",
    },
  ],
  [
    "VIEWS",
    {
      LABEL: "Views",
      VALUE: "views",
    },
  ],
  [
    "DATE_CREATED",
    {
      LABEL: "Date created",
      VALUE: "create_at",
    },
  ],
  [
    "DATE_MODIFIED",
    {
      LABEL: "Date modified",
      VALUE: "last_edit",
    },
  ],
]);

export const SORT_BY = new Map([
  [
    "VIEWS",
    {
      LABEL: "Views",
      VALUE: "views",
    },
  ],
  [
    "DATE_CREATED",
    {
      LABEL: "Date created",
      VALUE: "create_at",
    },
  ],
  [
    "DATE_MODIFIED",
    {
      LABEL: "Date modified",
      VALUE: "last_edit",
    },
  ],
]);

export const SORT_BY_ORDER = new Map([
  [
    "ASCENDING",
    {
      LABEL: "Ascending",
      VALUE: 1,
    },
  ],
  [
    "DESCENDING",
    {
      LABEL: "Descending",
      VALUE: -1,
    },
  ],
]);

export const BLOCKCHAIN_OPTIONS = new Map([
  [
    "POLYGON",
    {
      LABEL: "Polygon",
      VALUE: "polygon",
    },
  ],
  // [
  //   "ETHEREUM",
  //   {
  //     LABEL: "Ethereum",
  //     VALUE: "ethereum",
  //   },
  // ],
]);

export const COLLECTION_OPTIONS = new Map([
  [
    "IP-NFT",
    {
      LABEL: "IP-NFT",
      VALUE: "ip-nft",
    },
  ],
]);

export const SALE_TYPE_OPTIONS = new Map([
  [
    "Direct_Sale",
    {
      LABEL: "Direct Sale",
      VALUE: "Direct Sale",
    },
  ],
  [
    "Request_Sale",
    {
      LABEL: "Request Sale",
      VALUE: "Request Sale",
    },
  ],
]);

export const REWARD_DISTRIBUTON_PROTOCOL_OPTIONS = new Map([
  [
    "Auto_Reward",
    {
      LABEL: "Auto Reward Distribution Protocol",
      VALUE: "Auto Reward Distribution Protocol",
    },
  ],
  [
    "Pull_Based_Reward",
    {
      LABEL: "Pull Based Reward Distribution Protocol",
      VALUE: "Pull Based Reward Distribution Protocol",
    },
  ],
]);

export const NFT_SEARCH_SORT_BY = new Map([
  [
    "RECENTLY_LISTED",
    {
      LABEL: "Recently Listed",
      VALUE: "Recently Listed",
    },
  ],
  [
    "PRICE_LOW_TO_HIGH",
    {
      LABEL: "Price Low - High",
      VALUE: "Price Low - High",
    },
  ],
  [
    "PRICE_HIGH_TO_LOW",
    {
      LABEL: "Price High - Low",
      VALUE: "Price High - Low",
    },
  ],
]);

export const PATENT_POOL_SEARCH_SORT_BY = new Map([
  [
    "RECENTLY_CREATED",
    {
      LABEL: "Recently Created",
      VALUE: "Recently Created",
    },
  ],
  [
    "NFT_LOW_TO_HIGH",
    {
      LABEL: "No. NFT Low - High",
      VALUE: "No. NFT Low - High",
    },
  ],
  [
    "NFT_HIGH_TO_LOW",
    {
      LABEL: "No. NFT High - Low",
      VALUE: "No. NFT High - Low",
    },
  ],
  [
    "CREATOR_LOW_TO_HIGH",
    {
      LABEL: "No. Creator Low - High",
      VALUE: "No. Creator Low - High",
    },
  ],
  [
    "CREATOR_HIGH_TO_LOW",
    {
      LABEL: "No. Creator High - Low",
      VALUE: "No. Creator High - Low",
    },
  ],
]);

export const LICENSE_SEARCH_SORT_BY = new Map([
  [
    "VERSION",
    {
      LABEL: "Version",
      VALUE: "Version",
    },
  ],
  [
    "DURATION",
    {
      LABEL: "Duration",
      VALUE: "Duration",
    },
  ],
  [
    "PRICE",
    {
      LABEL: "Price",
      VALUE: "Price",
    },
  ],
]);

export const IP_IMPORT_SUGGESTED_SECTION_TITLES_LABEL =
  "Suggested Section Titles";

export const IP_IMPORT_SUGGESTED_SECTION_TITLES = [
  IP_IMPORT_SUGGESTED_SECTION_TITLES_LABEL,
  "17. Overview",
  "18. Novelty & Unobviousness",
  "19. Limitations",
  "20. Prior Art",
  "21. Commercial Applications",
  "22. Commercial Interest",
  "23. Plans",
  "24. Language",
  "25. Third Party Code",
  "26. Open Source License",
  "27. Technology Readiness Level",
  "28. Demo",
  "29. Source Code & Executable",
  "30. Technical Description",
];

export const IP_IMPORT_TEMPLATE_OPTIONS = new Map([
  [
    "NUS",
    {
      LABEL: {
        primary: "NUS Disclosure Form Template",
        secondary:
          "17. Overview, 18. Novelty & Unobviousness, 19. Limitations, 20. Prior Art, 21. Commercial Applications, 22. Commercial Interest, 23. Plans, 24. Language, 25. Third Party Code, 26. Open Source License, 27. Technology Readiness Level, 28. Demo, 29. Source Code & Executable, 30. Technical Description",
      },
      VALUE: [
        "17. Overview",
        "18. Novelty & Unobviousness",
        "19. Limitations",
        "20. Prior Art",
        "21. Commercial Applications",
        "22. Commercial Interest",
        "23. Plans",
        "24. Language",
        "25. Third Party Code",
        "26. Open Source License",
        "27. Technology Readiness Level",
        "28. Demo",
        "29. Source Code & Executable",
        "30. Technical Description",
      ],
    },
  ],
  // [
  //   "NTU",
  //   {
  //     LABEL: {
  //       primary: "NTU Disclosure Form Template",
  //       secondary:
  //         "27. Technology Readiness Level,28. Demo, 29. Source Code & Executable, 30. Technical Description",
  //     },
  //     VALUE: [
  //       "27. Technology Readiness Level",
  //       "28. Demo",
  //       "29. Source Code & Executable",
  //       "30. Technical Description",
  //     ],
  //   },
  // ],
]);

export const DEMAND_BUDGET_AMOUNT_OPTIONS = new Map([
  [
    "under_10000",
    {
      LABEL: "Under $10,000",
      VALUE: "Under $10,000",
    },
  ],
  [
    "10000_50000",
    {
      LABEL: "$10,000 to $50,000",
      VALUE: "$10,000 to $50,000",
    },
  ],
  [
    "50000_100000",
    {
      LABEL: "$50,000 to $100,000",
      VALUE: "$50,000 to $100,000",
    },
  ],
  [
    "100000_500000",
    {
      LABEL: "$100,000 to $500,000",
      VALUE: "$100,000 to $500,000",
    },
  ],
  [
    "over_500000",
    {
      LABEL: "Over $500,000",
      VALUE: "Over $500,000",
    },
  ],
  [
    "to_be_discussed",
    {
      LABEL: "To be discussed",
      VALUE: "To be discussed",
    },
  ],
]);

export const DEMAND_SORT_FIELDS = new Map([
  [
    "SORT_BY",
    {
      LABEL: "Sort by",
      VALUE: "SortedBy",
    },
  ],
  [
    "SORT_ORDER",
    {
      LABEL: "Order",
      VALUE: "SortOrder",
    },
  ],
]);
