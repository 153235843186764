import { appBar, bodyWidth, theme } from "../../../../utils/theme";
import ip8chat_icon from "../../../../assets/logo/IP8Chat.svg";

export const styles = {
  dialogAccordion: {
    boxShadow: "none",
    mb: 2,
    mt: 2,
    width: "inherit",
    "&::before": {
      backgroundColor: "transparent",
    },
  },
  dialogAccordionMenuItem: {
    alignItems: "center",
    color: theme.palette.secondary.main,
    cursor: "pointer",
    display: "flex",
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 400,
    pb: 4,
    pt: 4,
    width: "100%",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  dialogAccordionMenuItemsContainer: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    pl: 10,
    width: "100%",
  },
  dialogAccordionMenuItemIcon: {
    alignItems: "center",
    display: "flex",
    mr: 2,
  },
  dialogAccordionSummary: {
    padding: 0,
  },
  dialogAccordionSummaryText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 1,
    fontWeight: 500,
    letterSpacing: 1.1,
    ml: 2,
    mr: 1,
    textTransform: "inherit",
  },
  dialogFooter: {
    alignItems: "center",
    display: "flex",
    pb: 0,
    pl: 3,
    pr: 3,
    pt: 0,
    width: bodyWidth.width,
  },
  dialogHeader: {
    alignItems: "center",
    display: "flex",
    height: appBar.height,
    pb: 0,
    pl: 3,
    pr: 3,
    pt: 0,
    width: bodyWidth.width,
  },
  dialogHeaderContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  dialogHeaderIcon: {
    alignItems: "center",
    color: theme.palette.grays[500],
    display: "flex",
    justifyContent: "center",
  },
  dialogListContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "flex-start",
    pb: 4,
    pt: 0,
    width: "100%",
  },
  dialogPaper: {
    maxHeight: "100vh",
    position: "absolute",
    right: 0,
    top: 0,
    width: "90%",
  },
  dialogText: {
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize * 1,
    fontWeight: 500,
    letterSpacing: 1.1,
    mb: 2,
    ml: 2,
    mr: 1,
    mt: 2,
    textTransform: "inherit",
  },
  dialogTextContainer: {
    alignItems: "center",
    display: "flex",
    mb: 0,
    ml: 3,
    mt: 0,
  },
  dialogTextIcon: {
    alignItems: "center",
    color: theme.palette.grays[500],
    display: "flex",
    justifyContent: "center",
  },
  divider: {
    borderColor: theme.palette.grays[300],
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: 0,
    borderTopWidth: 1,
    width: "inherit",
  },
  grow: {
    flexGrow: 1,
    ml: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  navMenuContainer: {
    alignItems: "center",
    display: { xs: "flex", sm: "none" },
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  navIcon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    ml: 6,
  },
  ip8chatNav: {
    width: 80,
    height: 20,
    zIndex: 2,
    backgroundImage: `url(${ip8chat_icon})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  },
};
