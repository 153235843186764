import { Box, Button, ClickAwayListener, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { GlobalContext } from "../../../../contexts/GlobalContext";

import { PROJECT_TYPES } from "../../../../utils/optionUtils";
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../../../../utils/routesUtil";
import { styles } from "./styles";

const NavProjectSelect = () => {
  const { state, dispatch } = useContext(GlobalContext);
  let navigate = useNavigate();
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const { t } = useTranslation();

  const { userProfile } = state;

  const handleCloseMenu = () => {
    setIsSelectMenuOpen(false);
  };

  const handleSelectOption = (type) => (evt) => {
    evt.preventDefault();

    dispatch({
      type: "SET_CURRENT_DRAFT_TYPE",
      payload: {
        currentDraftType: type,
        currentProject: undefined,
      },
    });

    // dispatch({
    //   type: "STORE_CURRENT_DRAFT",
    //   payload: draftEditorTemplate(),
    // });

    if (userProfile) {
      navigate(PRIVATE_ROUTES.get("EDITOR").PATH);
    } else {
      dispatch({
        type: "SET_TARGET_PAGE",
        payload: PRIVATE_ROUTES.get("EDITOR").PATH,
      });

      navigate(PUBLIC_ROUTES.get("LOGIN").PATH);
    }

    handleCloseMenu();
  };

  return userProfile && userProfile?.account_type !== "public" ? (
    <Box sx={styles.selectContainer}>
      <Button
        color="primary"
        sx={{ ...styles.actionButton, mr: 2 }}
        variant="outlined"
        onClick={() => {
          navigate(PRIVATE_ROUTES.get("CREATE_DEMAND").PATH);
        }}
      >
        {t("Post Demand")}
      </Button>
      <Button
        color="primary"
        onClick={() => setIsSelectMenuOpen(true)}
        sx={styles.actionButton}
        variant="contained"
      >
        {t("Post Project")}
      </Button>

      {isSelectMenuOpen && (
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <Paper sx={styles.selectMenu}>
            {Array.from(PROJECT_TYPES).map(([key, value], index) => (
              <Box
                key={index}
                onClick={handleSelectOption(value.VALUE)}
                sx={styles.selectOption}
              >
                <Box
                  style={{
                    backgroundImage: `url(${value.ICON})`,
                  }}
                  sx={styles.selectOptionImage}
                />
                {t(value.LABEL)}

                <Box sx={styles.grow} />

                <RiArrowRightSLine size={"1.4em"} />
              </Box>
            ))}
          </Paper>
        </ClickAwayListener>
      )}
    </Box>
  ) : null;
};

export default NavProjectSelect;
