import expireImage from "../assets/icons/safe_re_kiil.svg";
import AIS_LOGO from "../assets/institutes/AI_Singapore/logo_icon.jpg";
import NTU_LOGO from "../assets/institutes/Nanyang_Technological_University/logo_icon.webp";
import NUS_LOGO from "../assets/institutes/National_University_of_Singapore/logo_icon.png";
import SERI_LOGO from "../assets/institutes/Singapore_Eye_Research_Institute/dummy_logo.png";
import SMU_LOGO from "../assets/institutes/Singapore_Management_University/logo.png";
import POLYU_LOGO from "../assets/institutes/The_Hong_Kong_Polytechnic_University/logo.png";
import USPTO_LOGO from "../assets/institutes/United_States_Patent_and_Trademark_Office/logo.png";
import UM_LOGO from "../assets/institutes/Universiti_Malaya/logo_icon.png";

import { PROJECT_TYPES } from "./optionUtils";
import { GLOBAL_ROUTES } from "./routesUtil";

export const LANGUAGE_KEY = "cp-lng";
export const LOGIN_TOKEN = "cp-token";
export const RESET_PASSWORD_TEMP_KEY = "cp-repd";

export const apiUrl = window.location.hostname.endsWith(".ip8value.cn")
  ? process.env.REACT_APP_BACKEND_CN_API_URL
  : process.env.REACT_APP_BACKEND_API_URL;

export const SUPPORT_CONTACT_EMAIL = "contact@ip8value.com";

export const loginToken = () => {
  return localStorage.getItem(LOGIN_TOKEN);
};

export const storeToken = async (key, token) => {
  if (!!token) {
    localStorage.setItem(key, token);
  }
};

export const removeToken = (key) => {
  localStorage.removeItem(key);
};

export const logout = async (dispatch) => {
  removeToken(LOGIN_TOKEN);

  dispatch({
    type: "STORE_USER_PROFILE",
    payload: undefined,
  });

  dispatch({
    type: "SHOW_TOAST",
    payload: {
      action: undefined,
      message: "You have been logged out",
      isOpen: true,
    },
  });
};

export const deleteAccount = async (dispatch) => {
  removeToken(LOGIN_TOKEN);

  dispatch({
    type: "STORE_USER_PROFILE",
    payload: undefined,
  });

  dispatch({
    type: "SHOW_TOAST",
    payload: {
      action: undefined,
      message: "Your account has been deleted successfully!",
      isOpen: true,
    },
  });
};

export const prepareFormData = (data) => {
  let arrayOfProcessedStrings = [];

  for (const key in data) {
    const value =
      typeof data[key] === "object"
        ? /** Prepare the format of stringified obj by (1) Wrapping string in double quotes and (2) Replacing all double quotes to single quotes */
          data[key]?.length === undefined
          ? `"${JSON.stringify(data[key]).replace(/['"]+/g, "'")}"`
          : JSON.stringify(data[key])
        : typeof data[key] === "string"
        ? JSON.stringify(data[key])
        : data[key];

    const newStr = `"${key}": ${value}`;
    arrayOfProcessedStrings = [...arrayOfProcessedStrings, newStr];
  }

  return `{${arrayOfProcessedStrings.join(", ")}}`;
};

/** Add idx and stringify contents of pages */
export const preparePagesData = (data) => {
  const processedData = { ...data };

  const revisedPages = processedData.pages.map((page, index) => {
    let obj = { ...page };
    obj["contents"] = JSON.stringify(page.contents);
    obj["idx"] = index;

    return obj;
  });
  processedData["pages"] = revisedPages;

  return processedData;
};

export const unpackIpData = (data) => {
  const processedData = { ...data };
  const jsonCover = !!data["cover"]
    ? JSON.parse(data["cover"].replace(/['"]+/g, '"'))
    : "";

  const jsonPages = data.pages.map((page) => {
    let obj = { ...page };
    /** TO RESTORE after internal db cleanup */
    // obj["contents"] = page.contents === "" ? "" : JSON.parse(page.contents);

    /** TO REMOVE (START) after internal db cleanup */
    let pageContent = "";
    if (page.contents === "") {
      pageContent = "";
    } else {
      try {
        /** Process previous page content format from internal system */
        // const processedString = JSON.stringify(page.contents).replaceAll(
        //   '\\"',
        //   ""
        // );
        // pageContent = JSON.parse(processedString);

        pageContent = JSON.parse(page.contents);
      } catch (err) {
        console.log(
          "Parsing error due to previous syntax. Resolve by updating section",
          err
        );
      }
    }

    obj["contents"] = pageContent;
    /** TO REMOVE (END) after internal db cleanup */

    return obj;
  });

  processedData["cover"] = jsonCover;
  processedData["pages"] = jsonPages;

  /** TO REMOVE After integrating institute APIs*/
  // processedData["project_owner_logo"] = assignLogoByInstitute(
  //   data.project_owner
  // );

  return processedData;
};

/** IMPORT FILE: Process import html, including table <tr> tags */

export const unpackImportResponseHtml = (data) => {
  let processedHtml = [];
  let counter = 0;

  while (counter < data.length) {
    /** Loop through all tags and stop once <tr> tag found. This is the START of a table  */
    if (data[counter]?.startsWith("<tr")) {
      let currentPoint = data.slice(counter);

      /** Group <tr> tags and append <table> and <tbody> tags at start and end */
      let trCounter = 0;
      let tableBody = [];

      /** Loop through all <tr> tags and stop once other tag found. This is the END of one table  */
      while (currentPoint[trCounter]?.startsWith("<tr")) {
        /** Remove imported file's table border color  */
        let processedString = currentPoint[trCounter].includes(
          "border: 1px solid #000000;"
        )
          ? currentPoint[trCounter].replaceAll("border: 1px solid #000000;", "")
          : currentPoint[trCounter];

        tableBody.push(processedString);

        trCounter++;
      }

      const table = [
        '<p><br></p><table class="table table-bordered"><tbody>',
        ...tableBody,
        "</tbody></table><p><br></p>",
      ];

      /** Append processed table html string to new html content */
      processedHtml.push(table.join(""));
      counter = counter + trCounter;
    } else {
      /** Append non-table tags to new html content */
      processedHtml.push(data[counter]);

      counter++;
    }
  }

  return processedHtml;
};

export const unpackSummaryData = (data) => {
  const processedData = { ...data };

  try {
    const jsonCover = JSON.parse(data["cover"].replace(/['"]+/g, '"'));

    processedData["cover"] = jsonCover;
  } catch (err) {
    // console.error(err);
    processedData["cover"] = "";
  }

  /** TO REMOVE After integrating institute APIs*/
  // processedData["project_owner_logo"] = assignLogoByInstitute(
  //   data.project_owner
  // );

  return processedData;
};

// eslint-disable-next-line no-unused-vars
// const INSTITUTIONS = new Map([
//   [
//     "AI Singapore",
//     {
//       ACRONYM: "AIS",
//       LOGO: AIS_LOGO,
//       NAME: "AI Singapore",
//     },
//   ],
//   [
//     "National University of Singapore",
//     {
//       ACRONYM: "NUS",
//       LOGO: NUS_LOGO,
//       NAME: "National University of Singapore",
//     },
//   ],
//   [
//     "Singapore Eye Research Institute",
//     {
//       ACRONYM: "SERI",
//       LOGO: SERI_LOGO,
//       NAME: "Singapore Eye Research Institute",
//     },
//   ],
//   [
//     "Singapore Management University",
//     {
//       ACRONYM: "SMU",
//       LOGO: SMU_LOGO,
//       NAME: "Singapore Management University",
//     },
//   ],
//   [
//     "Universiti Malaya",
//     {
//       ACRONYM: "UM",
//       LOGO: UM_LOGO,
//       NAME: "Universiti Malaya",
//     },
//   ],
// ]);

/** !!!TODO: Remove after backend supports returning institute logos with Ip summary information. Currently logos are retrieved from local assets folder  */
// const assignLogoByInstitute = (institute) => {
//   let logo;

//   switch (institute) {
//     case "AI Singapore":
//       logo = AIS_LOGO;
//       break;

//     case "Nanyang Technological University":
//       logo = NTU_LOGO;
//       break;

//     case "National University of Singapore":
//       logo = NUS_LOGO;
//       break;

//     case "Singapore Eye Research Institute":
//       logo = SERI_LOGO;
//       break;

//     case "Singapore Management University":
//       logo = SMU_LOGO;
//       break;

//     case "The Hong Kong Polytechnic University":
//       logo = POLYU_LOGO;
//       break;

//     case "United States Patent and Trademark Office":
//       logo = USPTO_LOGO;
//       break;

//     case "Universiti Malaya":
//       logo = UM_LOGO;
//       break;

//     default:
//       logo = "";
//   }

//   return logo;
// };

/** List of Institutes with institute pages. */
/** !!! TODO Stored & retrieve list from backend */
// export const institutePages = [
//   "AI Singapore",
//   "Nanyang Technological University",
//   "National University of Singapore",
//   "Singapore Eye Research Institute",
//   "Singapore Management University",
//   "United States Patent and Trademark Office",
//   "Universiti Malaya",
// ];

export const handleLoginTokenExpired = (dispatch, navigate) => {
  removeToken(LOGIN_TOKEN);

  dispatch({
    type: "STORE_USER_PROFILE",
    payload: undefined,
  });

  dispatch({
    type: "SET_ERROR_PAGE_CONTENT",
    payload: {
      image: expireImage,
      header: "Login token expired",
      body: "Your login token has expired. Please login again.",
    },
  });

  navigate(GLOBAL_ROUTES.get("PAGE_NOT_FOUND").PATH);
};

export const convertProjectTypeValueToKey = (value) => {
  const key = Array.from(PROJECT_TYPES.keys()).find(
    (type) => PROJECT_TYPES.get(type).KEY === value
  );

  return key;
};

export const convertToDataUrl = async (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  await new Promise((resolve) => (reader.onload = () => resolve()));

  return reader.result;
};

export const extractErrorMessage = (str) => {
  /** Error message is second item in error log, embedded between '<>'. */
  const extractMessage = str
    .match(/<(.*?)>/g)?.[1]
    .replace("<", "")
    .replace(">", "");

  /** Convert first char of error message to upper case */
  return extractMessage
    ? extractMessage.charAt(0).toUpperCase() + extractMessage.slice(1)
    : "Something went wrong. Please try again.";
};

/** Finds the end index of the first JSON object in a string. AIGC API use. */
export const findJsonObjectEndIndex = (str, start = 0) => {
  let depth = 0; // To track nested JSON objects
  let inQuotes = false; // To track if we are inside a string
  for (let i = start; i < str.length; i++) {
    const char = str[i];
    if (char === '"' && str[i - 1] !== "\\") {
      inQuotes = !inQuotes; // Toggle inQuotes state
    } else if (!inQuotes) {
      if (char === "{") {
        depth++; // Entering a nested JSON object
      } else if (char === "}") {
        depth--; // Exiting a nested JSON object
        if (depth === 0) {
          return i + 1; // Found the end of the JSON object
        }
      }
    }
  }
  return -1; // If no complete JSON object found
};
