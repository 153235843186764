import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import bulbIcon from "../../../assets/icons/bulb_icon.png";
import { GlobalContext } from "../../../contexts/GlobalContext";

import { styles } from "./styles";

const ConfirmDialog = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const { confirmDialog } = state;

  const onClickConfirm = () => {
    confirmDialog.action();
    closeDialog();
  };

  const closeDialog = () => {
    dispatch({
      type: "SET_CONFIRM_DIALOG",
      payload: {
        data: undefined,
        action: undefined,
        message: undefined,
        isOpen: false,
      },
    });
  };

  return confirmDialog.isOpen ? (
    <Dialog
      PaperProps={{ sx: styles.dialogPaper }}
      maxWidth={"lg"}
      open={confirmDialog.isOpen}
      onClose={closeDialog}
    >
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.dialogBodyContainer}>
          <Box sx={styles.imageWrapper}>
            <Box
              sx={styles.image}
              style={{
                backgroundImage: `url(${
                  confirmDialog.message.icon || bulbIcon
                })`,
              }}
            />
          </Box>

          <Box sx={styles.dialogColumn}>
            <Typography sx={styles.dialogHeader}>
              {confirmDialog.message === undefined
                ? t("Are you sure?")
                : t(confirmDialog.message.header)}
            </Typography>

            <Typography sx={styles.dialogText}>
              {confirmDialog.message === undefined
                ? t("Click confirm to proceed.")
                : t(confirmDialog.message.body)}
            </Typography>
          </Box>
        </Box>

        <Box sx={styles.dialogActionContainer}>
          <Button
            color="primary"
            onClick={onClickConfirm}
            sx={styles.actionButton}
            variant="contained"
          >
            {confirmDialog.message.action === undefined
              ? t("Confirm")
              : t(confirmDialog.message.action)}
          </Button>

          <Button
            onClick={closeDialog}
            sx={styles.actionButton}
            variant="outlined"
          >
            {t("Cancel")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  ) : null;
};

export default ConfirmDialog;
